// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

import {IProductEnvironment} from "./FacilitiesEvaluationEnvironment";
import {FacilityAccessibilityProduct} from "../product.facility/model/FacilityAccessibilityProduct";
import {EProductType} from "../javascript.lib.mojo-base/product.common/ProductType";
import {FacilityAccessibilityProduct2} from "../product.facility/model/FacilityAccessibilityProduct2";
import {FacilityAccessibilityProduct4} from "../product.facility/model/FacilityAccessibilityProduct4";

export const environment: IProductEnvironment = {

  filename: 'environment.facilities-pilot.ts',
  isDevelopment: false,
  isProduction: true,
  isTest: false,


  firebaseConfig: {
    apiKey: "AIzaSyASGMgdC8JxAtXvzNW8f6caSLHC8QcD3Ug",
    appId: "1:394490253021:web:75c28401c3b993d1719534",
    authDomain: "facilities-pilot.firebaseapp.com",
    databaseURL: "https://facilities-pilot-default-rtdb.europe-west1.firebasedatabase.app",
    messagingSenderId: "394490253021",
    projectId: "facilities-pilot",
    storageBucket: "facilities-pilot.appspot.com",
  },

  // curl -iLS https://us-central1-$_TARGET_ENV.cloudfunctions.net/public/functions/info
  cloudFunctionsUrl: 'https://us-central1-facilities-pilot.cloudfunctions.net',

  productConfig: {

    firebaseAnswersRoot: '/answers-x/facilities',
    productType: EProductType.facilities,

  },

  // product: new FacilityAccessibilityProduct(),
  // product:  FacilityAccessibilityProduct2.INSTANCE
  product:  FacilityAccessibilityProduct4.INSTANCE,

  supportsSubmitButton: true

};
